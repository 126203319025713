<!-- eslint-disable max-len -->
<template>
  <article class="c-content--sendPoint c-scrollbar">
    <div class="c-content--app_container">
      <div style='padding-bottom: 28px'>
        <div class='text-xl-bold' style='padding-bottom: 16px;'>Fecha de recojo</div>
        <ChooseOptionButton @click='openModalSelectDate'>
          <template v-slot:icon>
            <Icon name='Receipt-bill' strokeColor='#FF6568' />
          </template>
          <template v-slot:content>
            {{ deliveryDate }}
          </template>
        </ChooseOptionButton>
      </div>

      <div class='order_confirmed--info' style='padding-bottom: 28px'>
        <div class='text-xl-bold'>Dirección de recojo</div>
        <ChooseOptionButton :isSelectable='false'>
          <template v-slot:icon>
            <Icon name='Pin13' strokeColor='#FF6568' />
          </template>
          <template v-slot:content>
            <div>
              <div class='text-m-regular'>{{ origin.description }}</div>
              <div class='text-s-regular' style='color: #737373'>{{ origin.name }}</div>
            </div>
          </template>
        </ChooseOptionButton>
      </div>

      <div class='order_confirmed--info' style='padding-bottom: 28px'>
        <p class='text-xl-bold'>¿Quién envía?</p>
        <DestinationContactButton
          @click="openContactModal('origin')"
          :destinationContact='originContact'
        />
      </div>

      <div class="c-content--spacing-block">
        <div class='text-xl-bold'>Paquetes</div>
        <div class="c-content--scroll-package c-scrollbar overflow-x">
          <div
            v-for="(item, index) in lstDestination"
            v-bind:key="index"
            class="collection c-collection c-collection--not-hover c-collection--card c-collection--card__item border-none c-content--box-shadow"
          >
            <PackageSummary
              :includeDeleteButton='false'
              :packageDataIndex='index'
              :packageData='item'
              @onEdit="editDestinationAddress(item, index)"
            />
          </div>
        </div>
      </div>

      <div class="c-content--spacing-block" style='padding-bottom: 28px' v-if='!IS_AN_EMBASSY_USER'>
        <div class='text-xl-bold' style='padding-bottom: 16px;'>Tipo de comprobante</div>
        <ChooseOptionButton @click='openTypeVoucher'>
          <template v-slot:icon>
            <Icon name='Receipt-bill' strokeColor='#FF6568' />
          </template>
          <template v-slot:content>
            {{ !voucher ? "Escoge un comprobante" : voucher.typeVoucher }}
          </template>
        </ChooseOptionButton>
      </div>

      <div v-if="user && !user.esUsuarioProgramado && !IS_AN_EMBASSY_USER" class="c-content--spacing-block" style='padding-bottom: 28px'>
        <div class='text-xl-bold' style='padding-bottom: 16px;'>Método de pago</div>
        <ChooseOptionButton @click='openTypeCard'>
          <template v-slot:icon>
            <Icon name='BankCardDouble' strokeColor='#FF6568' />
          </template>
          <template v-slot:content>
            {{ !card ? 'Método de pago' : `${card.cardBrand} ${card.cardNumber}` }}
          </template>
        </ChooseOptionButton>
      </div>

      <div class="c-content--spacing-block" style='padding-bottom: 28px' v-if='!IS_AN_EMBASSY_USER'>
        <div class='text-xl-bold' style='padding-bottom: 16px;'>¿Tienes un cupón de descuento?</div>
        <span v-if="coupon && coupon.maxUses !== 0">Este cupón tiene {{ coupon.maxUses - coupon.uses }} usos disponibles.</span>
        <ChooseOptionButton @click='openCoupon'>
          <template v-slot:icon>
            <Icon name='Discount-ticket' strokeColor='#FF6568' />
          </template>
          <template v-slot:content>
            {{ coupon ? `${coupon.code} (${coupon.discountMessage})` : 'Ingresa un cupón' }}
          </template>
        </ChooseOptionButton>
        <div v-if='coupon' style='text-decoration: underline; cursor: pointer' @click='deleteCoupon'>Eliminar cupón</div>
      </div>

      <div style='display: flex; gap: 12px; padding-top: 8px; padding-bottom: 16px;'>
        <CustomCheckbox
          checkboxId='TYC'
          v-model='tyc'
        />
        <div class='text-m-regular'>
          He leído y acepto:
          <a
            href="https://saio.holascharff.com/Files/terminos-y-condiciones-reg.pdf"
            target="popup"
            onclick="window.open('https://saio.holascharff.com/Files/terminos-y-condiciones-reg.pdf','popup','width=600,height=600'); return false;"
            style="text-decoration: underline"
            class='text-m-regular'
            >Términos y Condiciones</a
          >
        </div>
      </div>

    </div>
  </article>
</template>

<script>
import { createMultipedidoProgramado } from "../../../services/client";
import PackageSummary from '@/components/PackageSummary.vue'
import Icon from '@/components/Icon.vue'
import ChooseOptionButton from '@/components/Button/ChooseOptionButton.vue'
import CustomCheckbox from '@/components/Form/CustomCheckbox.vue'
import DestinationContactButton from "../../Button/DestinationContactButton.vue"

export default {
  name: "Step4Content",
  components: {
    PackageSummary,
    ChooseOptionButton,
    Icon,
    CustomCheckbox,
    DestinationContactButton,
  },
  data() {
    return {
      fare: 0,
      tyc: false,
    };
  },

  methods: {
    async fetchCards() {
      this.showLoading();
      await this.$store
        .dispatch("GET_CARDS")
        .then((success) => {
          this.$swal.close();
          if (success.status === 200) {
            const cards = success.data.result;
            this.$store.dispatch("GENERAL_SET_CARDS", cards);
            if (cards.length > 0) {
              this.$store.dispatch("PROGRAMMED_CARD", cards[0]);
            }
          } else {
            this.showError(success.data.message);
          }
        })
        .catch((error) => {
          const msg = error?.data?.message || "Ocurrio un error.";
          this.showError(msg);
        });
    },
    setModel(origin, destination) {
      const fareRequest = {
        PackageValue: destination.productValue,
        PackageWidth: destination.packageSize.width,
        PackageHeight: destination.packageSize.height,
        PackageDeep: destination.packageSize.lengths,
        PackageWeight: destination.packageSize.weight,
        PackageType:
          destination.packageSize.type === "Pequeno"
            ? "Pequeño"
            : destination.packageSize.type,
        isMoreThan10: false,
        IsServiceExpress: false,
        IsServiceProgrammed: true,
        isServiceScharffPoint: false,
        OriginDistrictId: origin.districtId.id,
        DestinationDistrictId: destination.destinationAddress.districtId,
        ProgrammedServiceDate: null,
        IsUsingSubscription: false,
      };
      return fareRequest;
    },
    async loadFare() {
      let fare = 0;
      const { origin } = this;
      this.destinations.forEach((element) => {
        const fareRequest = {
          PackageValue: element.productValue,
          PackageWidth: element.packageSize.width,
          PackageHeight: element.packageSize.height,
          PackageDeep: element.packageSize.lengths,
          PackageWeight: element.packageSize.weight,
          PackageType: element.packageSize.type,
          isMoreThan10: false,
          IsServiceExpress: false,
          IsServiceProgrammed: true,
          isServiceScharffPoint: false,
          OriginDistrictId: origin.districtId.id,
          DestinationDistrictId: element.destinationAddress.districtId.id,
          ProgrammedServiceDate: null,
          IsUsingSubscription: false,
        };
        this.$store
          .dispatch("PROGRAMMED_CALCULATE_FARE", fareRequest)
          .then((response) => {
            fare += response.totalFare;
          })
          .catch((error) => {
            this.$swal({
              title: "Error!",
              text: error,
              icon: "error",
              confirmButtonText: "Aceptar",
            });
          });
      });

      this.fare = fare;
      this.$store.dispatch("PROGRAMMED_FARE", this.fare);
    },
    openModalSelectDate() {
      this.openModal("modal-date");
      this.trackEvent('click_programado_paso_4_fechas', 'programado')
    },
    openTypeVoucher() {
      this.openModalDismissible("modalTypeVoucher");
      this.trackEvent('click_programado_paso_4_comprobante', 'programado')
    },
    openTypeCard() {
      this.openModalDismissible("modalTypeCard");
      this.trackEvent('click_programado_paso_4_metodo_pago', 'programado')
    },
    openTip() {
      this.openModalDismissible("modalTip");
    },
    openCoupon() {
      this.openModalDismissible("modalCoupon");
      this.trackEvent('click_programado_paso_4_cupon', 'programado')
    },
    deleteCoupon() {
      this.$store.dispatch("PROGRAMMED_COUPON", null);
      const destinations = this.order.Destination;
      destinations.forEach((element) => {
        if (element.discountFare) {
          element.discountFare = null;
          element.coupon = null;
        }
      });
      this.$store.dispatch("PROGRAMMED_APPEND_DESTINATION", destinations);
      this.showLoading();
      const sumall = this.order.Destination.map((item) =>
        item.discountFare ? parseFloat(item.discountFare) : item.fare
      ).reduce((prev, curr) => prev + curr, 0);
      this.$store.dispatch("PROGRAMMED_FARE", sumall);
      this.$swal.close();

      this.trackEvent('click_programado_paso_4_eliminar_cupon', 'programado')
    },
    async validateProgrammedStep4() {
      if (!this.voucher && !this.IS_AN_EMBASSY_USER) {
        this.showError("Por favor ingrese los datos del comprobante de pago.");
        return false;
      }

      if (
        this.fareData !== 0 &&
        !this.card &&
        !this.user?.esUsuarioProgramado
        && !this.IS_AN_EMBASSY_USER
      ) {
        this.showError("Por favor ingrese un método de pago.");
        return false;
      }

      if (!this.tyc) {
        this.showError("Por favor acepte los términos y condiciones.");
        return false;
      }

      this.showLoading(
        // eslint-disable-next-line max-len
        "Estamos creando tu pedido. Ten en cuenta que esto puede tardar varios segundos dependiendo de la cantidad de destinos."
      );

      if(this.order?.card?.cardId == -1){
        this.trackEvent('click_paso_4_crear_pedido_pago_efectivo', 'programado')
      }else{
        this.trackEvent('click_paso_4_crear_pedido', 'programado')
      }

      await this.createOrder();
      return true;
    },
    async createOrder() {
      await this.$store
        .dispatch("PROGRAMMED_CREATE_FARE")
        .then(async (data) => {
          const response = await createMultipedidoProgramado(data);
          console.log(
            "🚀 ~ file: Step4-Content.vue:360 ~ .then ~ response",
            response
          );
          this.$swal.close();
          if (response.status >= 200 && response.status < 400) {
            this.$store.dispatch("SET_PAYMENT_RESULT", response.data);
            this.$store.dispatch("PROGRAMMED_PROGRESS_NAV_STEP", 5);
            this.$store.dispatch("STYLE_HOME_DIV");
            this.$store.dispatch("STYLE_EXPRESS_STEP_DIV_W_BG");

            if (response.data.extraParameters) {
              this.trackEvent('paso_4_crear_pedido_pago_efectivo_success', 'programado')
              var pagoEfectivoLink = response.data.extraParameters.URL_PAYMENT_RECEIPT_HTML;
              if(pagoEfectivoLink){
                window.open(pagoEfectivoLink, '_blank');
              }
            }else{
              this.trackEvent('paso_4_crear_pedido_success', 'programado')
            }

            if (response.canTakeSurvey) {
              this.$store.dispatch("HELPER_SET_SERVICE_TYPE_FOR_SURVEY", 'Programados')
              const modal = document.querySelector("#modalProductMarketFitSurvey")
              const instance = M.Modal.init(modal, {
                dismissible: false
              })
              if (instance) instance.open()
            }

            return true;
          }
          this.trackEvent('paso_4_crear_pedido_error', 'programado')
          this.showError(
            response.data.message ||
              "Ocurrio un error inesperado, inicie sesión nuevamente."
          );
          return false;
        })
        .catch((error) => {
          console.log(
            "🚀 ~ file: Step4-Content.vue:369 ~ createOrder ~ error",
            error
          );
          this.$swal.close();
          this.showError(
            error?.data?.message ||
              "Ocurrio un error inesperado, inicie sesión nuevamente."
          );
          return false;
        });
    },
    editDestinationAddress(destination, index) {
      this.$store.dispatch("PROGRAMMED_ORDER_SET_INDEX_DESTINATION", index);
      this.$eventBus.$emit("programmedSetDataDestinationModal", destination);
      const modalpackage = document.querySelector("#modal-package");
      modalpackage.classList.add("is-active");
      document.getElementById("programmed-step-3-modal-pop").value =
        destination.destinationAddress.districtId.text;

      this.trackEvent('click_programado_paso_4_editar_paquete', 'programado')
    },

    openContactModal(type) {
      this.trackEvent('programado_paso_2_click_boton_contacto', 'programado')
      this.showLoading();
      this.$store
        .dispatch("GET_CONTACTS")
        .then((success) => {
          this.$swal.close();
          if (success.status === 200) {
            const contacts = success.data.result.list;
            this.$store.dispatch("GENERAL_SET_CONTACTS", contacts);
            this.$store.dispatch("HELPER_SET_MODAL_CONTACT_TYPE", type);
            const modalContactFavorite =
              document.querySelector("#modal-contact-origin");
            const instance = M.Modal.init(modalContactFavorite, {
              dismissible: false,
            });
            instance.open();
          } else {
            this.showError(success.data.message);
          }
        })
        .catch((error) => {
          const msg = error?.data?.message || "Ocurrio un error.";
          this.showError(msg);
        });
    },
  },
  computed: {
    IS_AN_EMBASSY_USER() {
      return this.$store.getters.IS_AN_EMBASSY_USER;
    },
    deliveryDate() {
      return this.$store.getters.PROGRAMMED_ORDER_DELIVERY_DATE;
    },
    origin() {
      return this.$store.getters.PROGRAMMED_ORDER_ORIGIN;
    },
    productValue() {
      return this.$store.getters.PROGRAMMED_PRODUCT_VALUE;
    },
    voucher() {
      return this.$store.getters.PROGRAMMED_VOUCHER;
    },
    card() {
      return this.$store.getters.PROGRAMMED_CARD;
    },
    destinations() {
      return this.$store.getters.PROGRAMMED_ORDER_DESTINATIONN;
    },
    coupon() {
      return this.$store.getters.PROGRAMMED_COUPON;
    },
    lstDestination() {
      return this.$store.getters.PROGRAMMED_ORDER_DESTINATIONN;
    },
    user() {
      return this.$store.getters.USER;
    },
    order() {
      return this.$store.getters.PROGRAMMED_ORDER;
    },
    fareData() {
      return this.$store.getters.PROGRAMMED_FARE;
    },
    originContact() {
      return this.$store.getters.PROGRAMMED_ORIGIN_CONTACT;
    },
  },
  mounted() {
    this.fetchCards();
    this.$eventBus.$on("validateProgrammedStep4", () => {
      this.validateProgrammedStep4();
    });
  },
};
</script>

<style></style>
